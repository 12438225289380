import axios from 'axios';
import router from '@/router/permission';
import { getToken, removeToken } from '@/utils/token';
import Vue from 'vue';
// 创建axios实例
const service = axios.create({
  timeout: 20000, // 请求超时时间
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  },

});

service.interceptors.request.use((config) => {
  if (getToken()) {
    config.headers.Authorization = `Bearer:${getToken()}`;
  }
  return config;
},
(error) => {
  // Do something with request error
  Promise.reject(error);
});

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
  router.replace({
    path: '/login',
    query: {
      redirect: router.currentRoute.fullPath,
    },
  });
};

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status) => {
  // 状态码判断
  switch (status) {
    // 401: 未登录状态，跳转登录页
    case 401:
      toLogin();
      break;
    // 404请求不存在
    case 404:
      //router.replace({path: '/404'});
      Vue.prototype.$message.error('未知请求,请与管理员联系');
      break;
    default:
      //router.replace({path: '/500'});
      Vue.prototype.$message.error('服务正忙,请稍后再试');
      break;
  }
};

service.interceptors.response.use(
  // 请求成功   (res) => (res.status === 200 ? Promise.resolve(res) : Promise.reject(res))
  (res) => {
    const reLoginCode = new Set([3004, 3010, 3009, 3008]);
    if (res.data.statusCode && reLoginCode.has(res.data.statusCode)) {
      removeToken();
      router.replace({
        path: '/login',
      });
    }
    return res;
  },
  (error) => {
    // 请求已发出，但是不在2xx的范围
    errorHandle(error.status);
    return Promise.reject(error);
  },
);

export default service;
