import request from "@/utils/request";

const MENUS = [
    /*{
        enName: 'menus.home',
        menuIcon: 'home',
        path: '/home',
    },
    {
        type: 'accordion',
        menuList: [
            {
                enName: 'menus.crm',
                menuIcon: 'verified_user',
                menuList: [
                    {
                        enName: 'menus.customerManagement',
                        path: '/customerManagement',
                    },
                ],
            },
        ],
    },*/
];

export default function getMenus() {
    const url = `${window.login.apiUrl}/user/${window.endPoint}/menus/adm/v1`;
    request.get(url) //, {params: {userId: 4452969}
        .then((response) => {
            if (response.data.statusCode !== 2000) {
                return null;
            } else {
                const mid = response.data.data;
                const chidlren = [];
                for (let i = 0; i < mid.length; i++) {
                    if (mid[i].menuList === undefined || mid[i].menuList.length === 0) {
                        MENUS.push(mid[i])
                    } else {
                        chidlren.push(mid[i])
                    }
                }
                const child = {menuType: 'accordion', menuList: chidlren};
                MENUS.push(child)
            }
        });

    return MENUS;
}
