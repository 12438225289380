import request from "@/utils/request";
import store from '@/store';

export default function getUserInfo() {
    const url = `${window.system_admin.apiUrl}/login/getUser/v1`;
    request.get(url)
        .then((response) => {
            if (response.data.statusCode !== 2000) {
                return null;
            } else {
                localStorage.setItem('USERINFO', JSON.stringify(response.data.data));
                store.commit("SET_ID", response.data.data.id);
                store.commit("SET_NAME", response.data.data.name);
                store.commit('SET_ISFIRST', response.data.data.flag);
                store.commit('SET_IMAGEURL',response.data.data.imageUrl);
                return true;
            }
        });
}
