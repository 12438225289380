export const page404 = {
  path: '/404',
  name: '404',
  meta: {
    title: '404',
  },
  component: () => import('@/views/error/404'),
};

export const page500 = {
  path: '/500',
  name: '500',
  meta: {
    title: '500',
  },
  component: () => import('@/views/error/500'),
};

export const pageLogin = {
  path: '/login',
  name: 'login',
  meta: {
    title: 'Login',
  },
  component: () => import('@/views/login/login.vue'),
};

export const pageRegister = {
  path: '/register',
  name: 'register',
  meta: {
    title: 'SignUp',
  },
  component: () => import('@/views/register/register.vue'),
};

export const pageForgotPassword = {
  path: '/forgot_password',
  name: 'forgot_password',
  meta: {
    title: 'Forgot Password',
  },
  component: () => import('@/views/forgot-password/forgot-password.vue'),
};

export const pageLock = {
  path: '/lockscreen',
  name: 'lockscreen',
  meta: {
    title: 'Lockscreen',
  },
  component: () => import('@/views/lockscreen/lockscreen.vue'),
};

export const routers = [
  pageLogin,
  pageRegister,
  pageLock,
  pageForgotPassword,
  page404,
  page500,
];
