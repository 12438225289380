export default {
    menus: {
        home: 'Home',
        crm: 'CRM',
        customerManagement: 'Customer Management',
        accountManagement: 'Account Management',


        dashboard: 'Dashboard',
        basics: 'Basics',
        grid: 'Grid',
        color: 'Color',
        boxShadow: 'Box Shadow',
        typography: 'Typography',
        genericClasses: 'Generic Classes',
        applications: 'Applications',
        chat: 'Chat',
        mailBox: 'Mail Box',
        note: 'Note',
        calendar: 'Calendar',
        todoList: 'Todo List',
        uIElements: 'UI Elements',
        alert: 'Alert',
        badgeChip: 'Badge & Chip',
        button: 'Button',
        collapse: 'Collapse',
        list: 'List',
        loading: 'Loading',
        notification: 'Notification',
        modal: 'Modal',
        panel: 'Modal',
        progressBar: 'Progress Bar',
        sidebar: 'Sidebar',
        steps: 'Steps',
        swiper: 'Swiper',
        tabs: 'Tabs',
        toast: 'Toast',
        tooltipPopover: 'Tooltip & Popover',
        trees: 'Tree',
        forms: 'Forms',
        formBasic: 'Form Basic',
        formValidation: 'Form Validation',
        formPicker: 'Form Picker',
        formEditor: 'Form Editor',
        tables: 'Tables',
        basicTable: 'Basic Table',
        dataTable: 'Data Table',
        charts: 'Charts',
        chartJs: 'Charts.js',
        authentication: 'Authentication',
        login: 'Login',
        register: 'Register',
        lockscreen: 'Lockscreen',
        recoverPassword: 'Recover Password',
        404: '404',
        500: '500',
        extraPage: 'Extra Page',
        timeline: 'Timeline',
    },
    nav: {
        home: 'Home',
        personal: 'Profile',
        pwdEdit: 'Profile-Password Modification',

        CRMBrowse: 'Customer Management',
        AccountBrowse: 'Account Management',
        AccountAdd: 'Account Management-Account Add',
        AccountEdit: 'Account Management-Account Edit',

        addHome: 'Home-Customer Add',
        addHotel: 'Hotel-Customer Add',
        addFlats: 'Flats-Customer Add',
        paAdd: 'Permissions Add',
    },
    title: {
        baseInfo: 'Basic Information',
        locationInfo: 'Location Information',
        normal: 'Normal',
        lock: 'Lock',
        otherInfo: 'Other Information'
    },
    views: {
        platformManagement: {
            person: {
                browse: {
                    serial: 'No',
                    name: 'Name',
                    phone: 'Phone',
                    mailbox: 'Email',
                    sta: 'User Sta',
                },
                addPerson: {
                    name: 'Name',
                    role: 'Roles',
                    userSex: 'Sex',
                    men: 'Men',
                    women: 'Women',
                    phone: 'Phone',
                    headImg: 'Head Portrait',
                    mailbox: 'Email',
                    qq: 'QQ',
                    model: 'Account Mode',
                    auto: 'Auto',
                    manual: 'Manual',
                    username: 'Username',
                    pwd: 'Password',
                    initialPwd: 'Initial Password',
                    pwdConfirm: 'Password Confirm',
                },
                rules: {
                    name: 'Please fill in the correct name',
                    roleIds: 'At least one role must be selected',
                    phone: 'Please fill in the correct mobile phone number',
                    mailbox: 'Please fill in the correct mailbox',
                    username: 'Please fill in the correct username',
                    pwd: 'Please fill in the correct password',
                },
                message: {
                    delPerson: 'Confirm to delete the user？',
                    reset: 'Confirm to reset the user password?',
                    pwdConfirm: 'The two passwords are different, please confirm and submit again',
                },
                placeholder: {
                    search: 'Name Or Phone Num',
                },
            },
        },
        common: {
            editPerson: 'Edit Person',
            editDate: 'Edit Data',
            operation: 'Operation',
            cancelDel: 'Delete cancelled',
            select: 'Select',
            tip: 'Tip',
            cancelMessage: 'This action will delete the filled option. Do you want to continue?',
            cancelOperation: 'Cancel the operation'
        },
        crm: {
            customerManagement: {
                browse: {
                    industryType: 'Industry Type',
                    serial: 'No',
                    name: 'Customer Name',
                    code: 'Customer Code',
                    groupCode: 'Group Code',
                    grade: 'Grade',
                    source: 'Source',
                    contacts: 'Contacts',
                    mailbox: 'Mail Box',
                    tel: 'Tel',
                    sta: 'Sta',
                    customerType: 'Customer Type',
                    userName: 'Username',
                    editDate: 'Edit Date',
                    resource: 'Resources',
                    password: 'Password',
                    perMethod: 'Post Method',
                    perResource: 'Resource Path'


                },
                addBrowse: {
                    name: 'Customer Name',
                    dicType: 'Industry Type',
                    contacts: 'Contacts',
                    source: 'Source',
                    tel: 'Tel',
                    grade: 'Grade',
                    remarks: 'Remarks',
                    longitude: 'Longitude',
                    latitude: 'Latitude',
                    area: 'Area',
                    detailAddress: 'Detail Address',
                    group: 'Membership Group',
                    pmsAuth: 'PMS Auth',
                    hotelCode: 'Hotel Code',
                    groupCode: 'Group Code',
                    customerType: 'Customer Type',
                },
                placeholder: {
                    search: 'Customer Name',
                    country: 'Country',
                    province: 'Province',
                    city: 'City',
                    county: 'County',
                },
                rules: {
                    dicType: 'Please select an industry type',
                    name: 'Please enter the correct customer name',
                    contacts: 'Please enter the correct contacts',
                    source: 'Please select a customer source',
                    tel: 'Please enter the correct phone number',
                    grade: 'Please select a customer grade',
                    customerType: 'Please select a customer type',
                    area: 'Please select the customer\'s location',
                    detailAddress: 'Please enter the correct detailed address',
                    group: 'Please select the group to which the customer belongs',
                    mailbox: 'Please enter the correct mailbox',
                    customerMangerList: 'Please select the engineer responsible for the customer',
                },
                message: {
                    delCustomer: 'Confirm to delete the customer？',
                    lock: 'Confirm to lock the customer？',
                    unLock: 'Confirm to unlock the customer？',
                }
            }
        }
    },
    buttons: {
        common: {
            add: 'Add',
            edit: 'Edit',
            del: 'Delete',
            cancel: 'cancel',
            confirm: 'Confirm',
            exit: 'Exit',
            search: 'Search',
            upload: 'Upload',
            pa: 'Permissions Assignment',
            next: 'Next',
            last: 'Last',
            reset: 'Reset',
            format: 'Format',
            editPwd: 'Password Modification',
            lock: 'Lock',
            unLock: 'Unlock',
            syn: 'Synchronism',
        }
    },
    ruleMessage: {
        name: 'Incorrect name format',
        username: 'Incorrect username format',
        pwd: 'Incorrect password format',
        phone: 'Incorrect phone number format',
        mailbox: 'Incorrect mailbox format',
        crmContacts: 'Incorrect contacts format',
        crmName:'Incorrect customer name format',

        crmDetailAddress:'Incorrect detail address format',

        routeName: 'Route name format is incorrect',
        menuName: 'Incorrect menu name format',
        enName: 'Menu internationalization variable format is incorrect',
        path: 'The menu routing path is not in the correct format',
        component: 'The menu routing component is not in the correct format',
        meta: 'The menu metadata format is incorrect',
    },
    dashboard: {
        profile: 'Profile',
        mailbox: 'Email',
        lock: 'Lock Screen',
        logout: 'Logout',
        crm: 'CRM Management',
        member: 'Memeber Management',
    },
};
