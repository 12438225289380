export default {
    install: function (Vue, opt) {
        Vue.mixin({
            methods: {
                //菜单路由名称校验
                ruleForRouteName: function (rule, value, callback) {
                    if (value == '' || value == undefined || value == null) {
                        callback();
                    } else {
                        const reg = /^[-A-Za-z0-9]{1,50}$/;
                        if ((!reg.test(value)) && value != '') {
                            callback(new Error(this.$t('ruleMessage.routeName')));
                        } else {
                            callback();
                        }
                    }
                },
                //菜单名称校验
                ruleForMenuName: function (rule, value, callback) {
                    if (value == '' || value == undefined || value == null) {
                        callback();
                    } else {
                        const reg = /^[\u4E00-\u9FA5A-Za-z0-9]{1,30}$/;
                        if ((!reg.test(value)) && value != '') {
                            callback(new Error(this.$t('ruleMessage.menuName')));
                        } else {
                            callback();
                        }
                    }
                },
                //菜单国际化变量名校验
                ruleForEnName: function (rule, value, callback) {
                    if (value == '' || value == undefined || value == null) {
                        callback();
                    } else {
                        const reg = /^[a-zA-Z0-9]+\.[_a-zA-Z0-9]+$/;
                        if ((!reg.test(value)) && value != '') {
                            callback(new Error(this.$t('ruleMessage.enName')));
                        } else {
                            callback();
                        }
                    }
                },
                //菜单路径校验
                ruleForPath: function (rule, value, callback) {
                    if (value == '' || value == undefined || value == null) {
                        callback();
                    } else {
                        const reg = /^(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
                        if ((!reg.test(value)) && value != '') {
                            callback(new Error(this.$t('ruleMessage.path')));
                        } else {
                            callback();
                        }
                    }
                },
                //菜单路由成分校验
                ruleForComponent: function (rule, value, callback) {
                    if (value == '' || value == undefined || value == null) {
                        callback();
                    } else {
                        const reg = /^[a-zA-Z0-9]{1,50}$/;
                        if ((!reg.test(value)) && value != '') {
                            callback(new Error(this.$t('ruleMessage.component')));
                        } else {
                            callback();
                        }
                    }
                },
                //菜单路由成分校验
                ruleForMeta: function (rule, value, callback) {
                    if (value == '' || value == undefined || value == null) {
                        callback();
                    } else {
                        const reg = /^\{[\s*"\w+":"\w+-.",*\s*]+\}$/;
                        if ((!reg.test(value)) && value != '') {
                            callback(new Error(this.$t('ruleMessage.meta')));
                        } else {
                            callback();
                        }
                    }
                },
                //姓名校验
                ruleForName: function (rule, value, callback) {
                    if (value == '' || value == undefined || value == null) {
                        callback();
                    } else {
                        const reg = /^[\u4E00-\u9FA5A-Za-z0-9]{1,15}$/;
                        if ((!reg.test(value)) && value != '') {
                            callback(new Error(this.$t('ruleMessage.name')));
                        } else {
                            callback();
                        }
                    }
                },
                //账号校验
                ruleForUserName: function (rule, value, callback) {
                    if (value == '' || value == undefined || value == null) {
                        callback();
                    } else {
                        const reg = /^[_a-zA-Z0-9]{6,12}$/;
                        if ((!reg.test(value)) && value != '') {
                            callback(new Error(this.$t('ruleMessage.username')));
                        } else {
                            callback();
                        }
                    }
                },
                //密码校验
                ruleForPwd: function (rule, value, callback) {
                    if (value == '' || value == undefined || value == null) {
                        callback();
                    } else {
                        const reg = /^[_a-zA-Z0-9]{6,12}$/;
                        if ((!reg.test(value)) && value != '') {
                            callback(new Error(this.$t('ruleMessage.pwd')));
                        } else {
                            callback();
                        }
                    }
                },
                //手机号校验
                ruleForPhone: function (rule, value, callback) {
                    if (value == '' || value == undefined || value == null) {
                        callback();
                    } else {
                        const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                        if ((!reg.test(value)) && value != '') {
                            callback(new Error(this.$t('ruleMessage.phone')));
                        } else {
                            callback();
                        }
                    }
                },
                //邮箱校验
                ruleForMailbox: function (rule, value, callback) {
                    if (value == '' || value == undefined || value == null) {
                        callback();
                    } else {
                        const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/;
                        if ((!reg.test(value)) && value != '') {
                            callback(new Error(this.$t('ruleMessage.mailbox')));
                        } else {
                            callback();
                        }
                    }
                },
                //客户名称校验
                ruleForCRMName: function (rule, value, callback) {
                    if (value == '' || value == undefined || value == null) {
                        callback();
                    } else {
                        const reg = /[A-Za-z0-9_\-\u4e00-\u9fa5]+/;
                        if ((!reg.test(value)) && value != '') {
                            callback(new Error(this.$t('ruleMessage.crmName')));
                        } else {
                            callback();
                        }
                    }
                },
                ruleForCRMContacts: function (rule, value, callback) {
                    if (value == '' || value == undefined || value == null) {
                        callback();
                    } else {
                        const reg = /^[\u4E00-\u9FA5A-Za-z]{1,20}$/;
                        if ((!reg.test(value)) && value != '') {
                            callback(new Error(this.$t('ruleMessage.crmContacts')));
                        } else {
                            callback();
                        }
                    }
                },
                ruleForCRMTel: function (rule, value, callback) {
                    if (value == '' || value == undefined || value == null) {
                        callback();
                    } else {
                        const reg = /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/;
                        if ((!reg.test(value)) && value != '') {
                            callback(new Error(this.$t('ruleMessage.phone')));
                        } else {
                            callback();
                        }
                    }
                },
                ruleForCRMDetailAddress: function (rule, value, callback) {
                    if (value == '' || value == undefined || value == null) {
                        callback();
                    } else {
                        const reg = /^[\u4E00-\u9FA5A-Za-z0-9-]{1,255}$/;
                        if ((!reg.test(value)) && value != '') {
                            callback(new Error(this.$t('ruleMessage.crmDetailAddress')));
                        } else {
                            callback();
                        }
                    }
                },
            }
        })
    }

}