import Cookies from 'js-cookie';
import router from '@/router/permission'
import store from "@/store";

const TokenKey = 'xzzh_crm_token';
const RefTokenKey = 'xzzh_crm_refsh_token';
const UserKey = 'xzzh_crm_user';

export function getToken() {
    /*if(router.query){
        Cookies.set(TokenKey, router.query.token);
        Cookies.set(RefTokenKey, router.query.refrshtoken);
    }*/
    return Cookies.get(TokenKey);
}

export function setRefToken(token) {
    return Cookies.set(RefTokenKey, token);
}

export function getRefToken() {
    return Cookies.get(RefTokenKey);
}

export function setToken(token) {
    return Cookies.set(TokenKey, token);
}


export function removeToken() {
    Cookies.remove(RefTokenKey);
    return Cookies.remove(TokenKey);
}

export function getUser() {
    return Cookies.get(UserKey);
}

export function setUser(user) {
    return Cookies.set(UserKey, user);
}
