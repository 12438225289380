import Vue from 'vue';
import VueRouter from 'vue-router';
import {Message} from 'element-ui';
import NProgress from 'nprogress'; // Progress 进度条
import 'nprogress/nprogress.css'; // Progress 进度条样式
import store from '@/store';
import {routers} from '@/router/router';
import {getToken, setRefToken, setToken, setUser, removeToken} from '@/utils/token';
import addRouter from './addRouter';
import request from "@/utils/request";

const enpoint = 3;
Vue.use(VueRouter);

// 路由配置
const RouterConfig = {
    mode: 'history',
    scrollBehavior: () => ({y: 0}),
    routes: routers,
};

const router = new VueRouter(RouterConfig);

const whiteList = ['/login'];

function gotoRouter(to, next) {
    let asyncRouter;
    if (store.state.user.mainRouter === undefined || store.state.user.mainRouter.length === 0) {
        getRouters().then((response) => {
            if (response.data.statusCode !== 2000 || response.data.data === undefined) {
                logout();
                next('/login');
            } else {
                store.commit('SET_MAIN_ROUTER', response.data.data);
                asyncRouter = addRouter(response.data.data);
                asyncRouter.push({path: '*', redirect: '/404', hidden: true});
                router.addRoutes(asyncRouter);
                store.commit('routedHad', true); // 记录路由获取状态
                store.dispatch('setRouterList', asyncRouter); // 存储到vuex
                next({...to, replace: true}); // hack方法 确保addRoutes已完成
            }
        });
    } else {
        asyncRouter = addRouter(store.state.user.mainRouter);
        asyncRouter.push({path: '*', redirect: '/404', hidden: true});
        router.addRoutes(asyncRouter);
        store.commit('routedHad', true); // 记录路由获取状态
        store.dispatch('setRouterList', asyncRouter); // 存储到vuex
        next({...to, replace: true}); // hack方法 确保addRoutes已完成
    }
}

router.beforeEach((to, from, next) => {
    NProgress.start();
    if (to.query) {
        if (to.query.token && to.query.refreshtoken) {
            setToken(to.query.token);
            setRefToken(to.query.refreshtoken);
            getMenus().then((response) => {
                if (response.data.statusCode !== 2000 || response.data.data === undefined) {
                    logout();
                    next('/login');
                } else {
                    const MENUS = [];
                    const mid = response.data.data;
                    const chidlren = [];
                    for (let i = 0; i < mid.length; i++) {
                        if (mid[i].menuList === undefined || mid[i].menuList.length === 0) {
                            MENUS.push(mid[i])
                        } else {
                            chidlren.push(mid[i])
                        }
                    }
                    const child = {menuType: 'accordion', menuList: chidlren};
                    MENUS.push(child);
                    store.commit('SET_MENUS', MENUS);
                }
            });
            getButtons().then((response) => {
                if (response.data.statusCode !== 2000 || response.data.data === undefined) {
                    logout();
                    next('/login');
                } else {
                    store.commit('SET_BUTTONS', response.data.data);
                }
            });
            getUser().then((response) => {
                if (response.data.statusCode !== 2000 || response.data.data === undefined) {
                    logout();
                    next('/login');
                } else {
                    store.commit('SET_ID', response.data.data.id);
                    store.commit('SET_NAME', response.data.data.name);
                    store.commit('SET_ISFIRST', response.data.data.flag);
                    store.commit('SET_IMAGEURL',response.data.data.imageUrl);
                }
            });
            to.query.token = undefined;
            to.query.refreshtoken = undefined;
        }
    }
    if (getToken()) {
        // 判断cookice是否存在 不存在即为未登录
        if (to.path !== '/login') {
            if (store.state.routed) {
                // 无需再次请求 直接放行
                next();
            } else {
                gotoRouter(to, next);
            }
        } else {
            //Message({message: '您已经登录', type: 'info'});
            next('/');
        }
    } else {
        store.commit('routedHad', false);
        if (whiteList.indexOf(to.path) !== -1) {
            // 免登陆白名单 直接进入
            next();
        } else {
            if (to.path !== '/login') {
                // 重定向到登录页面 不能这么写 因为假如之前的角色是 管理员页面 后又登陆了非管理员 重定向的页面就可能不存在,就会导致404
                // next(`/login?redirect=${to.path}`)
                next('/login');
            }
            next();
        }
    }
});

router.afterEach(() => {
    NProgress.done(); // 结束Progress
});

async function getRouters() {
    const url = `${window.login.apiUrl}/user/router/${window.endPoint}/v1`;
    return await request.get(url);
}

async function getButtons() {
    const url = `${window.login.apiUrl}/user/button/${window.endPoint}/v1`;
    return await request.get(url);
}

async function getMenus() {
    const url = `${window.login.apiUrl}/user/${window.endPoint}/menus/adm/v1`;
    return await request.get(url);
}

async function getUser() {
    const url = `${window.system_admin.apiUrl}/login/getUser/v1`;
    return await request.get(url);
}


async function logout() {
    const url = `${window.login.apiUrl}/logout`;
    return await request.get(url);
}
export default router;
