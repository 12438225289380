import Main from '@/views/main';
import {forEach} from "@/base/vary-ui/components/base/dom";


/**
 * 生成路由
 * @param {Array} routerlist 格式化路由
 * @returns
 */
function addRouter(routerlist) {
    if (routerlist === undefined) {
        return null;
    }
    const router = [];
    routerlist.forEach((e) => {
        let eNew = {
            path: e.path,
            name: e.name,
        };
        if (e.children) {
            eNew = {...eNew, children: addRouter(e.children)};
        }
        if (e.component) {
            if (e.meta.tag === 'main') {
                eNew = {...eNew, component: Main};
            } else {
                eNew = {...eNew, component: () => import(/* webpackChunkName: "group-foo" */ `@/views${e.component}`)};
            }
        }
        if (e.redirect) {
            eNew = {...eNew, redirect: e.redirect};
        }
        if (e.meta.nav !== '' && e.meta.title !== '') {
            eNew = {...eNew, meta: {title: e.meta.title, nav: e.meta.nav, keepAlive: e.meta.keepAlive}};
        } else if (e.meta.title !== '' && e.meta.nav === '') {
            eNew = {...eNew, meta: {title: e.meta.title, keepAlive: e.meta.keepAlive}};
        }
        router.push(eNew);
    });
    return router;
}

function f(a) {

    const HelloWorld = () => import(/* webpackChunkName: "group-foo" */ `${a}`);
    return HelloWorld
}

export default addRouter;
