<template>
    <div>
        <div class="loading">
        </div>
    </div>

</template>
<script>
    export default {
        name: 'loading' // 定义的组件名称 使用时写法：loading
    }
</script>

<style>
    .loading {
        position: fixed;
        left: 10em;
        top: 0;
        background: url('~@/assets/image/loading-quan.gif') center center no-repeat rgba(255, 255, 255, 0.8);
        width: 100%;
        height: 100%;
        z-index: 1000;
    }
</style>