import request from "@/utils/request";
import store from "@/store";

const BUTTONS = [];
export default function getButtons() {
    /*return [
         'crm-search',
      'crm-add',
      'crm-edit',
      'crm-del',
      'crm-lock',

    ];*/
    const url = `${window.login.apiUrl}/user/button/${window.endPoint}/v1`;
    request.get(url) //, {params: {userId: 4452969}}
        .then((response) => {
            if (response.data.statusCode !== 2000) {
                return null;
            } else {
                const mid = response.data.data;
                for (let i = 0; i < mid.length; i++) {
                    BUTTONS.push(mid[i])
                }
            }
        });
    return BUTTONS;
}
