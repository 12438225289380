<!-- 函数式组件 -->
<script>
import store from '@/store';

export default {
  functional: true, // 函数式组件
  props: {
    // 接收参数
    buttonName: {
      type: String,
      required: true,
      default: '',
    },
  },
  render(h, context) {
    const { props, scopedSlots } = context;
    return store.getters.buttons.includes(props.buttonName)
      ? scopedSlots.default({
        name: props.buttonName,
      })
      : null;
  },
};
</script>
