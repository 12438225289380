export default [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'Login',
    },
    component: () => import('@/views/login/login.vue'),
  },
  { path: '/404', component: () => import('@/views/404'), hidden: true },
];
