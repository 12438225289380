import getMainRouter from '@/api/mainRouter';
import {getToken} from '@/utils/token';
import {getRefToken, getUser} from '@/utils/token';
import getButtons from '@/api/buttons';
import StaticRouterMap from '@/router/index';
import getMenus from '@/api/menus';
import getUserInfo from "@/api/user";

const user = {
    state: {
        token: getToken(),
        refToken: getRefToken(),
        name: userInfo(),//getUserInfo('name')
        id: '', //getUserInfo('id'),
        avatar: '',
        roles: [],
        menus: menus(),
        buttons: buttons(),
        mainRouter: routers(), //routers(),
        RouterList: [], // 动态路由
        imageurl:'',
        isFirst: '',
    },
    mutations: {
        SET_ACCESS_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_REFRESH_TOKEN: (state, token) => {
            state.refToken = token;
        },
        SET_NAME: (state, name) => {
            state.name = name;
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar;
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles;
        },
        set_router: (state, RouterList) => {
            state.RouterList = RouterList;
        },
        SET_MENUS: (state, menus) => {
            state.menus = menus;
        },
        SET_BUTTONS: (state, buttons) => {
            state.buttons = buttons;
        },
        SET_MAIN_ROUTER: (state, mainRouter) => {
            state.mainRouter = mainRouter;
        },
        SET_ID: (state, id) => {
            state.id = id;
        },
        SET_ISFIRST: (state, flag) => {
            state.isFirst = flag;
        },
        SET_IMAGEURL:(state, imageurl)=>{
            state.imageurl = imageurl;
        }
    },
    actions: {
        // 动态设置路由 此为设置设置途径
        setRouterList({commit}, routerList) {
            commit('set_router', StaticRouterMap.concat(routerList)); // 进行路由拼接并存储
        },
    },
};

function menus() {
    if (getToken()) {
        return getMenus();
    }
}

function buttons() {
    if (getToken()) {
        return getButtons();
    }
}

function routers() {
    if (getToken()) {
        return getMainRouter();
    }
}

function userInfo() {
    if (getToken()) {
        getUserInfo();
    }
}

export default user;
