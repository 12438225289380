export default {

    menus: {
        home: '首页',
        crm: 'CRM',
        customerManagement: '客户管理',
        accountManagement: '商汤账户管理',
        personal: '个人中心',
        pwdEdit: '个人中心-密码修改',


        dashboard: '仪表盘',
        basics: '基础',
        grid: '网格',
        color: '颜色',
        boxShadow: 'Box Shadow',
        typography: 'Typography',
        genericClasses: 'Generic Classes',
        applications: '应用',
        chat: 'Chat',
        mailBox: 'Mail Box',
        note: 'Note',
        calendar: 'Calendar',
        todoList: 'Todo List',
        uIElements: 'UI 元素',
        alert: 'Alert',
        badgeChip: 'Badge & Chip',
        button: 'Button',
        collapse: 'Collapse',
        list: 'List',
        loading: 'Loading',
        notification: 'Notification',
        modal: 'Modal',
        panel: 'Modal',
        progressBar: 'Progress Bar',
        sidebar: 'Sidebar',
        steps: 'Steps',
        swiper: 'Swiper',
        tabs: '按钮',
        toast: '面包片',
        tooltipPopover: '工具提示 & 悬浮层',
        trees: '树型结构',
        forms: '表单',
        formBasic: '基本表单',
        formValidation: '表单验证',
        formPicker: '表单选取器',
        formEditor: '表单编辑',
        tables: '表格',
        basicTable: '基本表格',
        dataTable: '时间表格',
        charts: '图表',
        chartJs: 'chart.js',
        authentication: '身份验证',
        login: '登录',
        register: '注册',
        lockscreen: '锁屏',
        recoverPassword: '恢复密码',
        404: '404',
        500: '500',
        extraPage: '附加页',
        timeline: '时间线',
    },
    nav: {
        home: '首页',
        personal: '个人中心',
        pwdEdit: '个人中心-密码修改',
        CRMBrowse: '客户管理',
        AccountBrowse: '商汤用户管理',
        AccountAdd:'商汤用户管理-用户添加',
        AccountEdit:'商汤用户管理-用户编辑',

        addHome: '家居-客户添加',
        editHome: '家居-客户编辑',
        addHotel: '酒店-客户添加',
        editHotel: '酒店-客户编辑',
        addFlats: '公寓-客户添加',
        editFlats: '公寓-客户编辑',
        paAdd:'权限分配',
    },
    title: {
        normal: '正常',
        lock: '锁定',
        baseInfo: '基础信息',
        locationInfo: '位置信息',
        otherInfo: '其他信息'
    },
    views: {
        platformManagement: {
            person: {
                browse: {
                    serial: '序号',
                    name: '姓名',
                    phone: '手机号',
                    mailbox: '邮箱',
                    sta: '用户状态',
                },
                addPerson: {
                    name: '姓名',
                    role: '角色',
                    userSex: '性别',
                    men: '男',
                    women: '女',
                    phone: '手机号',
                    headImg: '头像',
                    mailbox: '邮箱',
                    qq: 'QQ',
                    model: '账号模式',
                    auto: '自动',
                    manual: '手动',
                    username: '账号',
                    pwd: '密码',
                    initialPwd: '初始密码',
                    pwdConfirm: '密码确认',
                },
                rules: {
                    name: '请填写正确的姓名',
                    roleIds: '至少应该选择一个角色',
                    phone: '请填写正确的手机号',
                    mailbox: '请填写正确的邮箱',
                    username: '请填写正确的用户名',
                    pwd: '请填写正确的密码',
                },
                message: {
                    delPerson: '是否删除该用户？',
                    reset: '是否重置该用户密码？',
                    pwdConfirm: '两次密码输入不同，请确认后再次提交',
                },
                placeholder: {
                    search: '姓名或手机号',
                },
            },
        },
        common: {
            editPerson: '修改人',
            editDate: '修改时间',
            operation: '操作',
            cancelDel: '取消删除',
            select: '请选择',
            tip: '提示',
            cancelMessage: '此操作将删除已填写选项, 是否继续?',
            cancelOperation: '取消该操作',
        },
        crm: {
            customerManagement: {
                browse: {
                    industryType: '行业类型',
                    serial: '序号',
                    name: '客户名称',
                    groupCode:'集团编号',
                    code: '客户编号',
                    grade: '客户级别',
                    source: '客户来源',
                    contacts: '联系人',
                    tel: '电话',
                    sta: '状态',
                    customerType: '客户类型',
                    userName:'用户名',
                    editDate:'修改时间',
                    password:'密码',
                    perResource: '资源路径',
                    perMethod: '请求方式',
                    resource:'资源'
                },
                addBrowse: {
                    name: '客户名称',
                    dicType: '行业类型',
                    contacts: '联系人',
                    source: '来源',
                    mailbox: '邮箱',
                    tel: '电话',
                    grade: '等级',
                    remarks: '备注',
                    longitude: '经度',
                    latitude: '纬度',
                    area: '所在国家/省份',
                    area1:'所在城市/地区',
                    detailAddress: '详细地址',
                    group: '所属集团',
                    pmsAuth: 'PMS授权',
                    hotelCode: '酒店code',
                    groupCode: '集团code',
                    customerType: '类型',
                },
                placeholder: {
                    search: '客户名称',
                    country: '国家',
                    province: '省',
                    city: '市',
                    county: '区县',
                },
                rules: {
                    dicType: '请选择一个行业类型',
                    name: '请输入正确的客户名称',
                    contacts: '请输入正确的联系人',
                    source: '请选择一个客户来源',
                    tel: '请输入正确的电话',
                    grade: '请选择一个客户等级',
                    customerType: '请选择一个客户类型',
                    area: '请选择客户所在地区',
                    detailAddress: '请输入正确的详细地址',
                    group: '请选择客户所属集团',
                    customerMangerList: '请选择客户对应负责工程人员',
                    mailbox: '请输入正确的邮箱',
                    userName: '请填写正确的用户名',
                    password: '请填写正确的密码',

                },
                message: {
                    delCustomer: '是否删除该客户？',
                    lock: '是否锁定该客户？',
                    unLock: '是否解除该客户锁定？',
                }
            }
        },
        dashboard: {
            profile: '个人中心',
            mailbox: '邮箱',
            lock: '锁屏',
            logout: '退出',
        },
    },


    buttons: {
        common: {
            add: '添加',
            edit: '编辑',
            del: '删除',
            cancel: '取消',
            exit: '退出',
            confirm: '确认',
            search: '搜索',
            upload: '上传',
            pa: '权限分配',
            next: '下一步',
            last: '上一步',
            reset: '重置',
            format: '格式化',
            editPwd: '密码修改',
            lock: '锁定',
            unLock: '解锁',
            syn: '同步'
        }
    },
    ruleMessage: {
        name: '姓名格式不正确',
        username: '用户名格式不正确',
        pwd: '密码格式不正确',
        phone: '电话号码格式不正确',
        mailbox: '邮箱格式不正确',
        crmContacts:'联系人格式不正确',
        crmName:'客户名称格式不正确',
        crmDetailAddress:'详细地址格式不正确',
        routeName: '路由名称格式不正确',
        menuName: '菜单名称格式不正确',
        enName: '菜单国际化变量格式不正确',
        path: '菜单路由路径格式不正确',
        component: '菜单路由成分格式不正确',
        meta: '菜单元数据格式不正确',

        hostLocation: '主机IP地址格式不正确',
        hostPort: '主机端口号格式不正确',
        configPort: '配置端口号格式不正确',
        cloudControlAddress: '平台控制地址格式不正确',

        lockCode: '主机编号格式不正确',
        hostCode: '主机编号格式不正确',
        lockId: '门锁格式不正确',
        lockSecret: '门锁秘钥格式不正确',

        deviceName: '设备名称格式不正确',
        vocieName: '设备语音别名格式不正确'
    },
    dashboard: {
        profile: '个人中心',
        mailbox: '邮箱',
        lock: '锁屏',
        logout: '退出',
        crm: '客户管理',
        member: '会员管理',
    },
};
