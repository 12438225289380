import Vue from 'vue';
import Vuex from 'vuex';

import user from './modules/user';
import setting from './modules/setting';
import getters from './getters';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    routed: false,
  },
  mutations: {
    routedHad: (state, status) => {
      state.routed = status;
    },
  },
  actions: {},
  modules: {
    user,
    setting,
  },
  getters,
});

export default store;
