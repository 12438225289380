import request from "@/utils/request";
import router from "@/router/permission";
import Main from "@/views/main";
import store from "@/store";

export default function getMainRouter() {
    /*return [
        {
            path: '/',
            component: Main,
            name: 'home',
            redirect: '/home',
            meta: {
                title: 'home',
                nav: 'home',
            },
            children: [
                {
                    path: 'home',
                    name: 'home_index',
                    meta: {
                        title: 'Dashboard',
                        nav: 'Dashboard',
                    },
                    component: () => import('@/views/home/home.vue'), // 采取按需加载
                },
                {
                    path: 'personal',
                    name: 'personal',
                    meta: {
                        title: 'Personal',
                        nav: 'Personal',
                    },
                    component: () => import('@/views/personal/personal.vue'),
                },
                {
                    path: 'pwdEdit',
                    name: 'pwdEdit',
                    meta: {
                        title: 'pwdEdit',
                        nav: 'pwdEdit',
                    },
                    component: () => import('@/views/personal/pwdEdit.vue'),
                },
            ],
        },
        {
            path: '/customerManagement',
            name: 'customerManagement',
            component: Main,
            redirect: '/customerManagement/browse',
            meta: {
                title: 'customerManagement',
                nav: 'customerManagement',
            },
            children: [
                {
                    path: 'browse',
                    name: 'browse',
                    meta: {
                        title: 'nav.CRMBrowse',
                        nav: 'nav.CRMBrowse',
                        keepAlive: true,
                    },
                    component: () => import('@/views/crm/customerManagement/browse'),
                },
                {
                    path: 'addHome',
                    name: 'addHome',
                    meta: {
                        title: 'nav.addHome',
                        nav: 'nav.addHome',
                        keepAlive: false,
                    },
                    component: () => import('@/views/crm/customerManagement/addHome'),
                },
                {
                    path: 'addFlats',
                    name: 'addFlats',
                    meta: {
                        title: 'nav.addFlats',
                        nav: 'nav.addFlats',
                        keepAlive: false,
                    },
                    component: () => import('@/views/crm/customerManagement/addFlats'),
                },
                {
                    path: 'addHotel',
                    name: 'addHotel',
                    meta: {
                        title: 'nav.addHotel',
                        nav: 'nav.addHotel',
                        keepAlive: false,
                    },
                    component: () => import('@/views/crm/customerManagement/addHotel'),
                },
                {
                    path: 'editHotel',
                    name: 'editHotel',
                    meta: {
                        title: 'nav.editHotel',
                        nav: 'nav.editHotel',
                        keepAlive: false,
                    },
                    component: () => import('@/views/crm/customerManagement/editHotel'),
                },
                {
                    path: 'editHome',
                    name: 'editHome',
                    meta: {
                        title: 'nav.editHome',
                        nav: 'nav.editHome',
                        keepAlive: false,
                    },
                    component: () => import('@/views/crm/customerManagement/editHome'),
                },
                {
                    path: 'editFlats',
                    name: 'editFlats',
                    meta: {
                        title: 'nav.editFlats',
                        nav: 'nav.editFlats',
                        keepAlive: false,
                    },
                    component: () => import('@/views/crm/customerManagement/editFlats'),
                },
            ],
        },
    ];*/

    let MAINROUTERS = [];
    const url = `${window.login.apiUrl}/user/router/${window.endPoint}/v1`;
    request.get(url)  // {params: {userId: 4452969}}

    getRouters().then((response) => {
        if (response.data.statusCode !== 2000) {
        } else {
            store.commit('SET_MAIN_ROUTER', response.data.data);
            response.data.data.forEach((min) => {
                MAINROUTERS.push(min);
            })
        }
    });
    return MAINROUTERS //MAINROUTERS;
}

async function getRouters() {
    const url = `${window.login.apiUrl}/user/router/${window.endPoint}/v1`;
    return await request.get(url);
}
