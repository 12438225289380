<template>
  <div>
    <router-view> </router-view>
  </div>
</template>

<script>
export default {
  components: {},
  methods: {
    addThemeLink() {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.id = "admin-theme";
      link.href = "assets/themes/white-dark.css";
      document.head.appendChild(link);
    },
  },
  mounted() {
    this.$store.commit("refresh_Router", true);
    this.addThemeLink();
  },
  data() {
    return {};
  },
  watch: {},
  created() {
    // 在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
          Object.assign(
              {},
              this.$store.state,
              JSON.parse(sessionStorage.getItem("store"))
          )
      );
    }
    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    // beforeunload事件在页面刷新时先触发
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
};
</script>

<style lang="less">
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font: 13px Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial,
  Lucida Grande, sans-serif;
  line-height: 1.5;
  color: #303548;
  background: #ecf0f4 !important;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #6e6e6e;
    outline: 1px solid #333;
  }

  &::-webkit-scrollbar-corner,
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
#nprogress .bar {
  background: #FE6500 !important; //自定义颜色
}
#nprogress .peg{
  box-shadow:0 0 10px #FE6500, 0 0 5px #FE6500!important;
}
@import "base/vary-ui/styles/base";
@import "base/vary-ui/styles/index";
@import "base/styles/widget/index";
</style>
