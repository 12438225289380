<template>
    <div :class="classes">
        <!--Aside-->
        <aside class="aside">
            <div class="aside-menu">
                <template v-for="(item) in MENUS">
                    <div class="aside-menu-item" v-if="!item.menuType" :key="item.enName">
                        <router-link :to="item.path">
                            <!--<va-icon :icon="item.menuIcon"></va-icon>-->
                            <i :class="item.menuIcon"></i>
                            <span>{{$t(item.enName)}}</span>
                        </router-link>
                    </div>
                    <va-collapse v-model="collapseValue" :accordion="true" v-else-if="item.menuType==='accordion'"
                                 :key="item.enName">
                        <va-collapse-item v-for="(citem, index) in item.menuList" :name="citem.enName"
                                          :key="index.toString()">
                            <template slot="header">
                                <!--<va-icon :icon="citem.menuIcon"></va-icon>-->
                                <i :class="citem.menuIcon"></i>
                                <span>{{$t(citem.enName)}}</span>
                                <va-badge v-if="citem.count" :count="citem.count" :fixed="false"></va-badge>
                            </template>
                            <template slot="content">
                                <va-list>
                                    <va-list-item v-for="(menu,j) in citem.menuList" :key="j.toString()">
                                        <!--路由-->
                                        <router-link :to="menu.path" v-if="menu.path">
                                            <va-list-item-text>
                                                {{$t(menu.enName)}}
                                            </va-list-item-text>
                                        </router-link>
                                        <!--折叠-->
                                        <va-collapse v-else-if="!menu.path" :accordion="true" v-model="collapseValue1"
                                                     :key="menu.enName">
                                            <va-collapse-item  :name="menu.enName">
                                                <template slot="header">
                                                    <i :class="menu.menuIcon"></i>
                                                    <span>{{$t(menu.enName)}}</span>
                                                    <va-badge v-if="menu.count" :count="menu.count"
                                                              :fixed="false"></va-badge>
                                                </template>
                                                <template slot="content">
                                                    <va-list>
                                                        <va-list-item v-for="(eitem,l) in menu.menuList"
                                                                      :key="l.toString()">
                                                            <router-link :to="eitem.path">
                                                                <va-list-item-text>
                                                                    {{$t(eitem.enName)}}
                                                                </va-list-item-text>
                                                            </router-link>
                                                        </va-list-item>
                                                    </va-list>
                                                </template>
                                            </va-collapse-item>
                                        </va-collapse>
                                    </va-list-item>
                                </va-list>
                            </template>
                        </va-collapse-item>
                    </va-collapse>
                </template>
            </div>
        </aside>
        <!--Top Nav-->
        <header class="nav current-theme">
            <div class="nav-logo">
                <!--<img src="assets/vary-logo.svg" alt="vary-admin" class="va-logo">-->
                <!--<svg width="94" height="94" viewBox="0 0 94 94" class="va-logo">
                    <defs>
                        <linearGradient id="linear-gradient" gradientUnits="userSpaceOnUse" x1="47" y1="94" x2="47">
                            <stop offset="0" stop-color="#1691f5"/>
                            <stop offset="1" stop-color="#3369E6"/>
                        </linearGradient>
                    </defs>
                    <g>
                        <rect width="94" height="94" rx="4" ry="4" fill="transparent"/>
                        <text x="10" y="75"
                              font-family="Tangerine"
                              font-size="80" font-weight="bold" :fill="svgColor" fill-rule="evenodd"> V
                        </text>
                    </g>
                </svg>-->
                <el-image :src="logoUrl" alt="" class="va-logo"></el-image>
                <span class="name">享住智慧云平台</span>
            </div>
            <div class="nav-menu ">
                <div class="nav-left">
                    <!--<va-button class="nav-menu-toggle" @click="toggle">-->
                    <!--<va-icon icon="menu"/>-->
                    <!--</va-button>-->
                    <div class="hamburger" :class="{'is-active': mini}" @click="toggle">
                        <span class="line"></span> <span class="line"></span> <span class="line"></span>
                    </div>
                    <va-input class="search-bar" placeholder="Search" :clear="true" icon="search" dir="right"/>
                </div>
                <div class="nav-right">
                    <!--<va-input class="m-r-20" placeholder="Search" :clear="true" icon="search" dir="right"/>-->
                    <!--<va-switch v-model="mini" class="m-r-20"></va-switch>-->
                    <a class="nav-link p-l-10 p-r-10 m-r-10" @click="toggleFullscreen">
                        <va-icon icon="crop_free"/>
                    </a>
                    <!--<va-dropdown :hover="true" direction="bottom-right" class="nav-dropdown app-sortcut">
                        <a>
                            <va-icon icon="apps"/>
                        </a>
                        <template slot="menu">
                            <va-row class="app-sortcut-list">
                                <va-col :lg="4" v-for="(item,index) in shortcuts" :key="index.toString()">
                                    <router-link :to="item.to">
                                        <va-icon :icon="item.icon" class="m-b-10"></va-icon>
                                        <p class="m-0">{{item.label}}</p>
                                    </router-link>
                                </va-col>
                            </va-row>
                        </template>
                    </va-dropdown>-->
                    <el-dropdown @command="langChange">
                            <span class="el-dropdown-link">
                              {{defaulLang}}<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                    v-for="lang in langs"
                                    :key="lang.value"
                                    :value="lang.value"
                                    :command="composeValue(lang)"> {{lang.label}}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <!--<va-dropdown direction="bottom-right" class="nav-dropdown notification-dropdown">
                        <a>
                            <va-badge :count="5" :notify="true">
                                <va-icon icon="notifications_none" class="animated infinite swing"/>
                            </va-badge>
                        </a>
                        <template slot="menu">
                            <div class="notification-header">
                                <h6>Notifications</h6>
                                <label class="label bg-danger">New</label>
                            </div>
                            <va-list>
                                <va-list-item v-for="(menu, index) in messages" :key="index.toString()">
                                    <va-avatar :src="menu.avatar"/>
                                    <va-list-item-text>
                                        <h5 class="notification-user">John Doe</h5>
                                        <p class="notification-msg">Lorem ipsum dolor sit amet, consectetuer elit.</p>
                                        <span class="notification-time">30 minutes ago</span>
                                    </va-list-item-text>
                                </va-list-item>
                            </va-list>
                            <div class="notification-footer">
                                Show All Notifications
                            </div>
                        </template>
                    </va-dropdown>-->
                    <va-dropdown :menu="menus" :keys="menuKeys" direction="bottom-right" class="profile-dropdown">
                        <a class="row justify-center">
                            <div style="white-space: nowrap">
                                <va-avatar
                                        v-if="sex == 1"
                                        :src="
                    headImage === undefined ||
                    headImage === null ||
                    headImage === ''
                      ? 'assets/images/avatar/2.png'
                      : headImage
                  "
                                        theme="primary"
                                        width="40px"
                                        height="40px"
                                />
                                <va-avatar
                                        v-else
                                        :src="
                    headImage === undefined ||
                    headImage === null ||
                    headImage === ''
                      ? 'assets/images/avatar/3.jpg'
                      : headImage
                  "
                                        theme="primary"
                                        width="40px"
                                        height="40px"
                                />
                                <span
                                        class="user-name"
                                        style="
                    width: 100px;
                    line-height: 42px;
                    height: 40px;
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 17px;
                  "
                                >{{ this.$store.state.user.name }}</span
                                >
                            </div>
                        </a>
                    </va-dropdown>
                </div>
            </div>
        </header>
        <!--Main Content-->
        <main class="main">
            <div class="va-content" id="content">
                <div class="page-header-title">
                    <va-panel class="w-100">
                        <h3 class="page-title text-capitalize">{{name}}</h3>
                        <div class="page-navigations">
                            <va-breadcrumb :data="navigations"></va-breadcrumb>
                        </div>
                    </va-panel>
                </div>
                <div class="content-wrapper">
                    <transition name="vInOut">
                        <router-view></router-view>
                    </transition>
                    <va-back-top>
                        <va-button shape="circle">
                            <va-icon icon="keyboard_arrow_up"></va-icon>
                        </va-button>
                    </va-back-top>
                </div>
            </div>
        </main>
        <!--Setting Sidebar-->
        <va-sidebar v-model="reveal" dir="right" class="setting-board">
            <div class="va-sidebar-header current-theme">
                <h4 class="m-0">Settings</h4>
                <va-icon icon="clear" class="close-btn" @click="close"></va-icon>
            </div>
            <div class="va-sidebar-body">
                <div class="pane">
                    <b>Fix Header</b>
                    <va-switch v-model="fixHeader"></va-switch>
                </div>
                <div class="pane">
                    <b>Fix Sidebar</b>
                    <va-switch v-model="fixSidebar"></va-switch>
                </div>
                <div class="pane pane-vertical">
                    <h4>Light Sidebar</h4>
                    <ul class="row">
                        <li class="col-3" v-for="(t,i) in themes.light" :key="i.toString()">
                            <a :class="themeClass(t)" @click="selectTheme(t)"></a>
                        </li>
                    </ul>
                </div>
                <div class="pane pane-vertical">
                    <h4>Dark Sidebar</h4>
                    <ul class="row">
                        <li class="col-3" v-for="(t,i) in themes.dark" :key="i.toString()">
                            <a :class="themeClass(t)" @click="selectTheme(t)"></a>
                        </li>
                    </ul>
                </div>
                <!--<div class="pane">-->
                <!--<va-switch label="dark" true-value="dark" false-value="white-dark"-->
                <!--@on-change="selectTheme"></va-switch>-->
                <!--</div>-->
            </div>
        </va-sidebar>
        <va-button class="settings-toggle" shape="circle" @click="toggleSidebar" size="lg">
            <va-icon icon="settings"></va-icon>
        </va-button>
    </div>
</template>

<script>
    import {addClass, removeClass, hasClass} from '@/base/vary-ui/components/base/dom';
    import {toggleFullScreen} from '@/base/vary-ui/components/base/utils';
    import {listen} from '@/base/vary-ui/components/base/event';
    import {removeToken} from '@/utils/token';
    import getMenus from '@/api/menus';

    export default {
        name: 'va-main',
        data() {
            return {
                sex: 1,
                clearMenus: '',
                headImage: this.$store.state.user.imageurl,
                logoUrl:'assets/image/logo.png',
                menuKeys: '1',
                MENUS: this.$store.state.user.menus,
                mini: false,
                fixHeader: true,
                fixSidebar: true,
                name: '',
                defaulLang: '中文',
                langs: [
                    {
                        value: 'zh',
                        label: '中文',
                    }, {
                        value: 'en',
                        label: 'English',
                    },
                ],
                menus: [
                    {
                        title: 'dashboard.profile',
                        icon: 'person',
                        onClick: () => {
                            this.$router.push({
                                name: 'personal',
                            });
                        },
                    },
                   /* {
                        title: 'dashboard.lock',
                        icon: 'lock',
                        onClick: () => {
                            localStorage.setItem('locking', 1);
                            this.$router.push({
                                name: 'lockscreen',
                            });
                        },
                    },*/
                    {
                        divider: true,
                    },
                    {
                        title: 'dashboard.logout',
                        icon: 'settings',
                        onClick: () => {
                            const url = `${window.login.apiUrl}/logout`;
                            this.$service.get(url)
                                .then((response) => {
                                    if (response.data.statusCode !== 2000) {
                                        this.$message.error(response.data.msg);
                                    } else {
                                        removeToken();
                                        this.$router.push({
                                            name: 'login',
                                        });
                                        window.location.reload();
                                    }
                                })
                        },
                    },
                ],
                reveal: false,
                messages: [
                    {
                        text: 'Grid',
                        path: '/gird',
                        avatar: 'assets/images/avatar/1.jpg',
                        icon: 'home',
                    },
                    {
                        text: 'Buttons',
                        path: '/buttons',
                        avatar: 'assets/images/avatar/1.jpg',
                        icon: 'home',
                    },
                    {
                        text: 'Lists',
                        path: '/lists',
                        avatar: 'assets/images/avatar/1.jpg',
                        icon: 'home',
                    },
                ],
                shortcuts: [
                    {
                        label: 'Calendar',
                        icon: 'today',
                        to: '/calendar',
                    },
                    {
                        label: 'Chat',
                        icon: 'apps',
                        to: '/chat',
                    },
                    {
                        label: 'TodoList',
                        icon: 'table_chart',
                        to: '/todo-list',
                    },
                    {
                        label: 'Gallery',
                        icon: 'image',
                        to: '/chat',
                    },
                    {
                        label: 'Note',
                        icon: 'note',
                        to: '/note',
                    },
                    {
                        label: 'Contact',
                        icon: 'person_pin',
                        to: '/chat',
                    },
                ],
                navigations: [
                    {

                        icon: 'home',
                        type: 'icon',
                        render(h, params) {
                            return h('va-icon', {
                                icon: params.icon,
                            });
                        },
                    },
                ],
                collapseValue: [],
                resizeListener: null,
                themes: {
                    light: ['blue', 'green', 'yellow', 'red', 'purple', 'megan'],
                    dark: ['white-dark', 'blue-dark', 'green-dark', 'yellow-dark', 'red-dark', 'purple-dark', 'megan-dark'],
                },
                currentTheme: 'white-dark',
            };
        },
        methods: {
            themeClass(t) {
                return [
                    `${t}-theme`,
                    {
                        active: this.currentTheme === t,
                    },
                ];
            },
            selectTheme(t) {
                this.currentTheme = t;
                this.$store.commit('setTheme', t);
                document.getElementById('admin-theme').href = `assets/themes/${t}.css`;
            },
            toggle() {
                this.mini = !hasClass(document.body, 'va-mini');
            },
            toggleSidebar() {
                this.reveal = !this.reveal;
            },
            close() {
                this.reveal = false;
            },
            toggleFullscreen() {
                toggleFullScreen();
            },
            fetchNavigation(nav) {
                const navigations = [this.navigations[0]];
                if (nav) {
                    const navs = nav.split('-');
                    navs.forEach((na) => navigations.push({
                        name: na,
                    }));
                    this.name = navs[navs.length - 1] || '';
                }
                this.navigations = navigations;
            },
            setFixHeader(fixed) {
                if (fixed) {
                    addClass(document.body, 'va-fix-header');
                } else {
                    removeClass(document.body, 'va-fix-header');
                }
            },
            setFixSidebar(fixed) {
                if (fixed) {
                    addClass(document.body, 'va-fix-sidebar');
                } else {
                    removeClass(document.body, 'va-fix-sidebar');
                }
            },
            handleResize() {
                this.mini = window.innerWidth <= 800;
            },
            // 语言切换
            langChange(e) {
                const es = e.split('/');
                [this.$i18n.locale, this.defaulLang] = [...es];
                localStorage.setItem('lang', es[0]);
                this.fetchNavigation(this.$t(this.$route.meta.nav));
            },
            composeValue(lang) {
                return `${lang.value}/${lang.label}`;
            },
        },
        computed: {
            classes() {
                return [
                    'app',
                ];
            },
            svgColor() {
                if (!/dark/.test(this.currentTheme)) {
                    return '#303548';
                }
                return this.currentTheme !== 'white-dark' ? '#fff' : 'url(#linear-gradient)';
            },
        },
        watch: {
            $route(to) {
                this.fetchNavigation(this.$t(to.meta.nav));
            },
            mini(val) {
                if (val) {
                    addClass(document.body, 'va-mini');
                } else {
                    removeClass(document.body, 'va-mini');
                }
            },
            fixHeader(val) {
                this.setFixHeader(val);
            },
            fixSidebar(val) {
                this.setFixSidebar(val);
            },
        },
        mounted() {
            this.fetchNavigation(this.$t(this.$route.meta.nav));
            this.setFixHeader(this.fixHeader);
            this.setFixSidebar(this.fixSidebar);
            this.handleResize();
            this.resizeListener = listen(window, 'resize', this.handleResize);
            if (!this.MENUS) {
                try {
                    let flag = 1
                    this.clearMenus = setInterval(() => {
                        flag++
                        this.MENUS = getMenus();
                        if (this.MENUS || flag > 5) {
                            clearInterval(this.clearMenus)
                        }
                    }, flag * 1000)
                } catch (e) {
                    console.log(e)
                }
            }
        },
        created(){
            if (localStorage.getItem("USERINFO")) {
                let ui = JSON.parse(localStorage.getItem("USERINFO"));
                if (ui && ui.userSex) {
                    this.sex = ui.userSex;
                }
            }
        },
        beforeDestroy() {
            if (this.resizeListener) {
                this.resizeListener();
                this.resizeListener = null;
            }
        },
    };
</script>

<style lang="less">
    @import "main";
</style>
