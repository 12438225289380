import Vue from 'vue';
import App from '@/app.vue';
import 'element-ui/lib/theme-chalk/index.css';
import i18n from '@/lang';
import authorized from '@/views/components/authorized';
import service from '@/utils/request';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'; // lang i18n
import validator from '@/utils/validator'
import router from './router/permission';
import store from './store';
import Vary from './base/vary-ui/components';
import BaiduMap from 'vue-baidu-map'
import loading from '@/views/components/loading';
import 'e-icon-picker/dist/index.css';//基础样式
import 'e-icon-picker/dist/main.css'; //fontAwesome 图标库样式

Vue.use(BaiduMap, {
    ak: 'ZXqhUozT9ZERc9z17xjRH7IInXDLZCjq'
});


Vue.use(Vary);
Vue.use(ElementUI, { locale });
Vue.use(validator);
Vue.component('xzzh-Authorized', authorized);
Vue.component('xzzh-loading', loading);

// 开启debug模式
Vue.config.debug = process.env.NODE_ENV === 'development';
Vue.prototype.$service = service;

/* eslint-disable no-new */
new Vue({
  el: '#root',
  store,
  router,
  i18n,
  render: (h) => (h(App)),
});
